import { takeLatest, put, call, select } from 'redux-saga/effects'

import { cheatsheets, customPresets } from '../actions/admin'
import { getPlayers, getSettings, getTeamStacks } from '../reducers/lineup'
import { getPlayerGroups } from '../reducers/groups'

import { partnerURI } from '../constants/api'
import { getToken } from '../utils/tokens'

import { partner as DEFAULT_COMPANY } from '../constants/partner'

const deepCopyObject = (o) => {
  return JSON.parse(JSON.stringify(o))
}

function* fetchCustomPresets({ site, slate, sport, counter, season }) {
  const token = getToken()

  const customPresetsURI = `${partnerURI}/${sport}/${DEFAULT_COMPANY}/${site}/${slate}/${season}/${counter}/custom-presets`
  let responseBody
  try {
    const response = yield call(fetch, customPresetsURI, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        authorization: token
      }
    })
    if (response.status !== 200 && response.status !== 201) {
      throw new Error("unable to fetch")
    }
    responseBody = yield call([response, response.json])

    yield put({type: customPresets.RECEIVE, payload: responseBody})
  } catch(e) {
    console.log(e)
    console.error("unable to fetch custom presets")
  }
}

function* updateCustomPresets({ site, slate, sport, counter, season, players, generalSettings, stackSettings, groups, resetAll, displayName }) {
  const token = getToken()

  const payload = {
    'players': {},
    'generalSettings': {},
    'stackSettings': {},
    'groups': {},
    'delete': false,
    displayName
  }

  if (!resetAll) {
    if (players) {
      let playerTable = yield select(getPlayers)
      playerTable = deepCopyObject(playerTable)
      payload.players = playerTable
    }

    if (generalSettings) {
      let generalSettings = yield select(getSettings)
      generalSettings = deepCopyObject(generalSettings)
      payload.generalSettings = generalSettings
    }

    if (stackSettings) {
      let stackSettings = yield select(getTeamStacks)
      stackSettings = deepCopyObject(stackSettings)
      payload.stackSettings = stackSettings
    }

    if (groups) {
      let customGroups = yield select(getPlayerGroups)
      customGroups = deepCopyObject(customGroups)
      payload.groups = customGroups
    }
  } else {
    payload.delete = true
  }

  const customPresetsURI = `${partnerURI}/${sport}/${site}/${slate}/${season}/${counter}/custom-presets`
  let responseBody
  try {
    const response = yield call(fetch, customPresetsURI, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        authorization: token
      },
      body: JSON.stringify({...payload})
    })
    responseBody = yield call([response, response.json])

    if (responseBody.success) {
      yield put({type: customPresets.UPDATE_COMPLETE})
    } else {
      yield put({type: customPresets.UPDATE_FAILED})
    }
  } catch(e) {
    console.error("unable to update custom presets")
    yield put({type: customPresets.UPDATE_FAILED})
  }
}

function* fetchCheatsheets({ site, slate, sport, counter, season }) {
  const token = getToken()

  const customPresetsURI = `${partnerURI}/${sport}/${DEFAULT_COMPANY}/${site}/${slate}/${season}/${counter}/cheatsheets`
  let responseBody
  try {
    const response = yield call(fetch, customPresetsURI, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        authorization: token
      }
    })
    responseBody = yield call([response, response.json])

    yield put({type: cheatsheets.RECEIVE, payload: responseBody})
  } catch(e) {
    console.error("unable to fetch custom presets")
  }
}

function* fetchUserCheatsheets({ site, slate, sport, counter, season }) {
  const token = getToken()

  const cheatsheetsURI = `${partnerURI}/${sport}/${DEFAULT_COMPANY}/${site}/${slate}/${season}/${counter}/cheatsheets`
  let responseBody
  try {
    const response = yield call(fetch, cheatsheetsURI, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        authorization: token
      }
    })
    responseBody = yield call([response, response.json])

    yield put({type: cheatsheets.RECEIVE, payload: responseBody})
  } catch(e) {
    console.error("unable to fetch custom presets")
  }
}

function* updateUserCheatsheets({ site, slate, sport, counter, season, userCheatsheets, resetAll, displayName }) {
  const token = getToken()

  const body = {
    'playerPicks': userCheatsheets,
    'delete': resetAll ? true : false,
    displayName
  }

  const cheatsheetsURI = `${partnerURI}/${sport}/${site}/${slate}/${season}/${counter}/cheatsheets`
  let responseBody
  try {
    const response = yield call(fetch, cheatsheetsURI, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        authorization: token
      },
      body: JSON.stringify({...body})
    })
    responseBody = yield call([response, response.json])

    if (responseBody.success) {
      yield put({type: cheatsheets.UPDATE_COMPLETE})
    } else {
      yield put({type: cheatsheets.UPDATE_FAILED})
    }
  } catch(e) {
    console.error("unable to update custom presets")
    yield put({type: cheatsheets.UPDATE_FAILED})
  }
}

/**
 * The root of the account saga.
 */
export default function* adminSagas() {
  yield takeLatest(
    customPresets.FETCH,
    fetchCustomPresets
  )
  yield takeLatest(
    customPresets.UPDATE,
    updateCustomPresets
  )
  yield takeLatest(
    cheatsheets.FETCH,
    fetchUserCheatsheets
  )
  yield takeLatest(
    cheatsheets.UPDATE,
    updateUserCheatsheets
  )
}
