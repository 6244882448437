export const framed = true
export const partner = 'fantasy_footballers'
export const overridePerms = true
export const authed = false
export const customPresets = true

// CSS Overrides
export const overridePrimary = '#42ab4e'
export const overrideSecondary = '#999'
export const overrideCheckboxColor = '#ad7a5e'
export const overrideButtonText = '#fff'
export const overrideBackground = null
export const overrideColor = "#000"
export const overridePlayerText = "#000"
export const overrideBackgroundSecondary = "fff"
// export const overrideTableHighlight = "#41464f"
export const overrideTableHighlight = "rgb(230, 247, 255)"
// TEMP remove
export const api_token = 'wuOOYr1ZInN5aG6LaoRtx4eFdZCsNAH6'
export const api_id = '207859'

export const allowCheatsheets = true